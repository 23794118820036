import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';
import LayoutStyles from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
  },
  image: {
    width: '100%',
  },
  checkList: {
    margin: theme.spacing(2, 0),
  },
  check: {
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  checkIcon: {
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  title: String,
  checkList: Object,
  image: String,
  imageOnLeft: Boolean,
};

function SectionCheck({ title, checkList, image, imageOnLeft }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.layout}>
      <Grid
        spacing={3}
        container
        style={{ alignItems: 'center', flexDirection: imageOnLeft ? 'row' : 'row-reverse' }}
      >
        <Grid item md={6} xs={12} align="center">
          <img src={image} className={classes.image} alt={title} />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography variant="h2" align="left">
            {t(title)}
          </Typography>
          <Grid className={classes.checkList}>
            {checkList.map(check => (
              <Grid container key={check} className={classes.check}>
                <CheckIcon className={classes.checkIcon} />
                <Typography variant="h6" align="left" component="div">
                  {t(check)}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default SectionCheck;
