import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LayoutStyles from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  fullWidthLayout: {
    ...LayoutStyles.fullWidthLayout,
  },
  title: {
    paddingBottom: theme.spacing(3),
    lineHeight: 1.2,
  },
  body: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(0.5),
  },
  subtitle: {
    paddingBottom: theme.spacing(3),
  },
  image: {
    width: '100%',
  },
}));

type Props = {
  title1: String,
  title2: String,
  title2Color: String,
  body: String,
  subtitle: String,
  image: String,
  titleH2: Boolean,
};

function SectionHeroOffer({ title1, title2, title2Color, body, subtitle, image, titleH2 }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      className={classes.fullWidthLayout}
      style={{ height: '100%', alignItems: 'center' }}
    >
      <Grid item md={6} xs={12}>
        <Typography variant={titleH2 ? 'h2' : 'h1'} className={classes.title}>
          {t(title1)}{' '}
          {title2 && (
            <span
              style={
                title2Color && {
                  background: title2Color,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  WebkitBoxDecorationBreak: 'clone',
                }
              }
            >
              {t(title2)}
            </span>
          )}
        </Typography>
        <Typography variant="h4" component={titleH2 ? 'h3' : 'h2'} className={classes.body}>
          {t(body)}
        </Typography>
        <Typography variant="subtitle2" component="div" className={classes.subtitle}>
          {t(subtitle)}
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <img src={image} className={classes.image} alt={`${title1} ${title2}`} />
      </Grid>
    </Grid>
  );
}
export default SectionHeroOffer;
