import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import { useRouteMatch } from 'react-router';

import { Images, muiTheme } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import SectionHeroOffer from '../../Components/PagesLeagues/SectionHeroOffer';
import SectionCheck from '../../Components/PagesLeagues/SectionCheck';
import SectionMatrice from '../../Components/PagesLeagues/SectionMatrice';
import SectionContentOffer from '../../Components/PagesLeagues/SectionContentOffer';
import SectionSlider from '../../Components/PagesLeagues/SectionSlider';
import SectionInfoCentered from '../../Components/PagesLeagues/SectionInfoCentered';
import FooterWello from '../../PagesLeagues/Wello/Footer';
import Footer from '../../Components/Layout/Footer';
import Seo from '../../Routing/Seo';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: 'white',
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '6rem 2rem 6rem',
  },
  heroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,
    width: '100%',
    height: '84vh',
    backgroundColor: theme.palette.common.greyBackground,
    padding: theme.spacing(9, 0),
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minHeight: '84vh',
    },
  },
  proofLayout: {
    width: '100%',
    background: `linear-gradient(to bottom, ${theme.palette.common.greyBackground}, white)`,
    padding: theme.spacing(7, 0, 9),
  },
  securityLayout: {
    width: '100%',
    backgroundColor: theme.palette.common.greyBackground,
    padding: theme.spacing(7, 0, 5),
  },
}));

const OfferHelperHelp = () => {
  const classes = useStyles();
  const match = useRouteMatch();

  return (
    <>
      <Helmet>
        {Seo.title("Wello | Solutions numériques pour l'aide aux aidants")}
        {Seo.description(
          'Des outils conçus pour les professionnels de l’aide aux aidants pour repérer les aidants, faire connaître ses actions, ses activités, ses solutions de répit.',
        )}
        {Seo.canonical(6, match.url)}
        {/* {Seo.image(isOrganisationReady && organisation?.holding?.cover_url)} */}
      </Helmet>

      <Grid className={classes.mainLayout}>
        <Grid className={classes.heroLayout}>
          <SectionHeroOffer
            title1="Des outils innovants pour l’aide aux"
            title2="aidants"
            title2Color={`linear-gradient(to bottom right, ${muiTheme.wello.helperhelp.light}, ${muiTheme.wello.helperhelp.main})`}
            body="Apporter des réponses dédiées aux aidants, afin qu’ils puissent trouver facilement du soutien par les professionnels, des activités, des solutions de répit, et échanger avec d’autres aidants."
            subtitle="PLATEFORME DE RÉPIT POUR PERSONNES ÂGÉES OU EN SITUATION DE HANDICAP, STRUCTURE ET ASSOCIATION D'AIDE AUX AIDANTS, RELAYAGE…"
            image={Images.offers.helperhelp.jumbo_illustration}
          />
        </Grid>

        <Divider style={{ padding: '4rem', background: 'none' }} />

        <SectionCheck
          title="Rencontrez les aidants"
          checkList={[
            'Gagnez en visibilité avec une page de présentation publique et référencée sur les moteurs de recherche',
            'Attirez et ciblez tous les aidants grâce à des formulaires de contact simples',
            "Donnez de la visibilité à votre travail en mettant en avant vos actions sur le fil d'actualité",
          ]}
          image={Images.offers.helperhelp.item1}
        />

        <Hidden smUp>
          <Divider style={{ padding: '4rem', background: 'none' }} />
        </Hidden>

        <SectionCheck
          title="Accompagnez vos aidants"
          checkList={[
            "Informez, orientez et conseillez vos aidants en leur partageant des articles, des documents et en animant le fil d'actualité de votre structure",
            'Maintenez un lien durable avec vos aidants grâce à un suivi personnalisé',
            "Partagez le suivi des demandes d'aide avec vos collègues",
          ]}
          image={Images.offers.helperhelp.item2}
          imageOnLeft
        />

        <Hidden smUp>
          <Divider style={{ padding: '4rem', background: 'none' }} />
        </Hidden>

        <SectionCheck
          title="Professionnalisez vos pratiques"
          checkList={[
            'Gérez vos activités et suivez les inscriptions grâce à un espace back-office simple',
            'Centralisez vos actions quotidiennes sur un seul et même outil',
          ]}
          image={Images.offers.helperhelp.item3}
        />

        <Hidden smUp>
          <Divider style={{ padding: '4rem', background: 'none' }} />
        </Hidden>

        <SectionCheck
          title="Modernisez vos pratiques"
          checkList={[
            'Fédérez et animez des groupes de pair-aidance via les communautés et les espaces de discussion',
            "Proposez des solutions de répit en ligne sur votre page d'accueil",
            "Échangez sur vos pratiques avec d'autres professionnels au sein de communautés thématiques",
          ]}
          image={Images.offers.helperhelp.item4}
          imageOnLeft
        />

        <Divider style={{ padding: '5rem', background: 'none' }} />

        <SectionInfoCentered
          title="Planifiez une démo !"
          subtitle="Vous avez un projet d’aide aux aidants, nous pouvons vous proposer un accès gratuit en moins de 10 minutes !"
          buttonTitle="Consulter notre agenda"
          buttonUrl="https://calendly.com/agenda-wello/demo"
          targetBlank
          btnColor={`linear-gradient(to bottom right, ${muiTheme.wello.helperhelp.light}, ${muiTheme.wello.helperhelp.main})`}
          backgroundColor={muiTheme.palette.common.greyBackground}
          mailto
        />

        <Divider style={{ padding: '5rem', background: 'none' }} />

        <Grid className={classes.proofLayout}>
          <SectionHeroOffer
            title1="Un outil qui fait"
            title2="ses preuves"
            title2Color={`linear-gradient(to bottom right, ${muiTheme.wello.helperhelp.light}, ${muiTheme.wello.helperhelp.main})`}
            body="Notre outil fait ses preuves en offrant aux aidants des informations et des ressources pour mieux comprendre et gérer les besoins de leur proche en situation de dépendance. Il les aide également à gérer leur propre bien-être en leur proposant les activités mises à leur disposition dans leur périmètre."
            image={Images.offers.helperhelp.device}
            titleH2
          />
        </Grid>

        <SectionContentOffer
          title="La Scalaa"
          subtitle="S'appuyer sur un réseau de bénévoles"
          paragraph="<p>La Maison des Aidants de Lille souhaitait pouvoir s'appuyer sur une réseau de bénévoles pour organiser la solidarité et aider les aidants dans leurs petits gestes du quotidien. Nous avons imaginé ensemble et développé les premières fonctionnalités permettant aux aidants de faire des demandes d’aide en ligne (Aide aux courses, aide au numérique, petit bricolage, etc.)</p><p>Pendant les confinements liés à la crise du COVID 19 ce module fut très pratique et très utilisé pour le soutien téléphonique et l’aide aux courses pour les aidants de personnes fragiles et particulièrement vulnérables.</p>"
          image={Images.offers.helperhelp.rex1}
          imageOnLeft
          buttonLabel="Découvrez la plateforme"
          buttonLink="https://www.soutenirlesaidants.fr/"
          targetBlank
          btnBackgroundColor={`linear-gradient(to bottom right, ${muiTheme.wello.helperhelp.light}, ${muiTheme.wello.helperhelp.main})`}
        />
        <Divider style={{ padding: '6rem', background: 'none' }} />
        <SectionContentOffer
          title="La prévention près de chez soi"
          subtitle="Mettre en avant et proposer des activités près de chez soi"
          paragraph="<p>Trouver des activités lorsque l’on est aidant n’est pas simple. Avec les professionnels des plateformes de répit des Hauts-de-France, nous avons imaginé un espace numérique où l’on peut retrouver l’ensemble des activités pour les aidants de personnes âgées ou en situation de handicap près de chez soi.</p><p>Nous avons d’abord enrichi d’un maximum d’activités et de contenu sur la région des Hauts-de-France permettant “de donner envie” aux autres partenaires avant ensuite de l'étendre à toute la France.</p>"
          image={Images.offers.helperhelp.rex2}
          buttonLabel="Découvrez soutenir les aidants"
          buttonLink="https://www.soutenirlesaidants.fr/"
          targetBlank
          btnBackgroundColor={`linear-gradient(to bottom right, ${muiTheme.wello.helperhelp.light}, ${muiTheme.wello.helperhelp.main})`}
        />
        <Divider style={{ padding: '6rem', background: 'none' }} />
        <SectionContentOffer
          title="Le relayage coordonné"
          subtitle="Organiser le relayage à domicile et échanger avec les professionnels"
          paragraph="<p>S’absenter de son domicile quelques heures ou quelques jours pendant qu’un professionnel vient prendre le relais. Les aidants souhaitent s’organiser avant le départ, trouver les meilleurs relayeurs pour leur proche, échanger et garder le contact avec les professionnels pendant leur absence.</p><p>Le service bulle d’air permet de mettre en place une solution de relayage. Wello et ses partenaires travaillent sur les outils numériques permettant la mise en relation et la coordination à domicile.</p>"
          image={Images.offers.helperhelp.rex3}
          imageOnLeft
          btnBackgroundColor={`linear-gradient(to bottom right, ${muiTheme.wello.helperhelp.light}, ${muiTheme.wello.helperhelp.main})`}
        />

        <Divider style={{ padding: '5rem', background: 'none' }} />
        <SectionInfoCentered
          title="Planifiez une démo !"
          subtitle="Vous avez un projet d’aide aux aidants, nous pouvons vous proposer un accès gratuit en moins de 10 minutes !"
          buttonTitle="Consulter notre agenda"
          buttonUrl="https://calendly.com/agenda-wello/demo"
          targetBlank
          btnColor={`linear-gradient(to bottom right, ${muiTheme.wello.helperhelp.light}, ${muiTheme.wello.helperhelp.main})`}
          backgroundColor={muiTheme.palette.common.greyBackground}
          mailto
        />
        <Divider style={{ padding: '2rem', background: 'none' }} />
        <SectionSlider
          title="Interopérabilité"
          subtitle="La plateforme Wello est conçue pour être interopérable avec les grands référentiels nationaux. Certains d’entre eux sont déjà opérationnels… Contactez-nous !"
          items={[
            {
              img: Images.offers.establishment.ref1,
              alt: 'Identité Nationale de Santé',
            },
            {
              img: Images.offers.establishment.ref2,
              alt: 'Mon Espace Santé',
            },
            {
              img: Images.offers.establishment.ref3,
              alt: 'Soutenir les Aidants',
            },
            {
              img: Images.offers.establishment.ref4,
              alt: 'Dossier Médical Partagé',
            },
            {
              img: Images.offers.establishment.ref5,
              alt: 'Ma Boussole Aidants',
            },
          ]}
        />
        <Divider style={{ padding: '2rem', background: 'none' }} />
        <Grid className={classes.securityLayout}>
          <SectionMatrice
            title="Un très haut niveau de sécurité et de confidentialité"
            rowItemSM={4}
            features={[
              {
                id: 70,
                image: Images.offers.securite1,
                title: 'Interopérabilité Ségur',
                subTitle:
                  'La plateforme Wello est conçue pour être un DUI labellisé Ségur (Dossier Usager Informatisé) et interopérable avec les grands référentiels nationaux (INS, DMP, MS Santé).',
              },
              {
                id: 71,
                image: Images.offers.securite2,
                title: 'Confidentialité et conformité',
                subTitle:
                  'Wello s’engage à fournir une plateforme conforme aux réglementations françaises et européennes.',
              },
              {
                id: 72,
                image: Images.offers.securite3,
                title: 'Sécurité des données de santé',
                subTitle:
                  'Les données sont hébergées en France sur des serveurs certifiés HDS. Wello protège vos données grâce à des contrôles tels que les autorisations et le chiffrement en transit et au repos.',
              },
            ]}
          />
        </Grid>

        {/* FOOTER SECTION */}
        <Divider style={{ padding: '4rem', background: 'none' }} />
        <FooterWello />
        <Footer />
      </Grid>
    </>
  );
};

export default OfferHelperHelp;
