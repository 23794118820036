import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LayoutStyles from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  relayageLayout: {
    ...LayoutStyles.fullWidthLayout,
    padding: theme.spacing(5, 0),
    borderRadius: '4px',
    [theme.breakpoints.only('xs')]: {
      padding: '2rem',
    },
  },
  subtitle: {
    margin: theme.spacing(3, 0),
  },
  mail: {
    marginTop: theme.spacing(1),
    '&>*': { color: 'inherit' },
  },
}));

type Props = {
  title: String,
  subtitle: String,
  buttonTitle: String,
  buttonUrl: String,
  backgroundColor: String,
  targetBlank: Boolean,
  btnColor: String,
  mailto: Boolean,
};

function SectionInfoCentered({
  title,
  subtitle,
  buttonTitle,
  buttonUrl,
  backgroundColor,
  targetBlank,
  btnColor,
  mailto,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      className={classes.relayageLayout}
      style={{
        backgroundColor: `${backgroundColor}`,
      }}
      align="center"
    >
      <Typography variant="h3">{t(title)}</Typography>
      <Typography variant="h6" className={classes.subtitle} component="div">
        {t(subtitle)}
      </Typography>
      <Button
        variant="contained"
        align="center"
        href={buttonUrl}
        target={targetBlank && '_blank'}
        style={{ background: btnColor, color: 'white' }}
      >
        {t(buttonTitle)}
      </Button>
      {mailto && (
        <Typography variant="body2" className={classes.mail}>
          ou envoyez-nous un message à <a href={'mailto:hello@wello.fr'}>hello@wello.fr</a>
        </Typography>
      )}
    </Grid>
  );
}
export default SectionInfoCentered;
