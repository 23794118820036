import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Footer from '../../Components/Layout/Footer';
import { Images, muiTheme } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import SectionContent from '../../Components/PagesLeagues/SectionContent';
import SectionHero from '../../Components/PagesLeagues/SectionHero';
import SectionMatrice from '../../Components/PagesLeagues/SectionMatrice';
import FooterWello from '../../PagesLeagues/Wello/Footer';
import Seo from '../../Routing/Seo';
import SectionCommunity from '../../Components/PagesLeagues/SectionCommunity';
import SectionInfo from '../../Components/PagesLeagues/SectionInfo';
import SectionSlider from '../../Components/PagesLeagues/SectionSlider';
import { useRouteMatch } from 'react-router';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: '#ffffff',
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '6rem 2rem 6rem',
  },
  headingContainer: {
    width: '100%',
    padding: '3rem 0 3rem',
  },
  root: {
    padding: theme.spacing(1, 1),
  },
  layoutPartner: {
    ...LayoutStyles.fullWidthLayout,
    padding: '2rem 3rem 3rem',
  },
}));

const OfferPartner = () => {
  const classes = useStyles();
  const match = useRouteMatch();

  return (
    <>
      <Helmet>
        {Seo.title('Wello | Solution numérique partenaires')}
        {Seo.description(
          'Aide aux aidants, prévention, mobilité, services à domiciles, relayage, empowercare...',
        )}
        {Seo.canonical(6, match.url)}
      </Helmet>
      <Grid className={classes.mainLayout}>
        {/* PARTNERS SECTION */}
        <SectionHero
          header="Partenaires"
          headerColor="white"
          title1="Deployez votre projet de"
          title1Color1="white"
          title1Color2="white"
          title2="bien commun"
          title2Color1="#C000FF"
          title2Color2="#FF29C1"
          body="Aide aux aidants, prévention, mobilité, services à domiciles, relayage, empowercare..."
          bodyColor="white"
          image={Images.offers.partner.jumbo_illustration}
          imageMD={5}
          imageWidth="500px"
          imageMarginTop={110}
          image2={Images.offers.partner.icones}
          backgroundImage={`url(${Images.offers.partner.jumbo})`}
          layoutHeight="92vh"
        />

        {/* SERVICES SECTION */}
        <Divider style={{ padding: '2rem', background: 'none' }} />
        <SectionMatrice
          title="Tous les services en ligne pour piloter votre action sur votre territoire"
          rowItemSM={12}
          features={[
            {
              id: 40,
              image: Images.offers.partner.main_screen,
            },
          ]}
        />

        <SectionContent
          title="Moteur de recherche et géolocalisation"
          subtitle="Une recherche intuitive, des solutions de proximité"
          paragraph="Bénéficiez d’un moteur de recherche performant : recherche par mots-clés (même avec des fautes d’orthographe), par ville, par structure. L’auto-complétion permet de suggérer des types de recherche et d’orienter les bénéficiaires ou aidants qui peuvent être perdus… Ainsi l’utilisateur peut trouver rapidement les professionnels, activités et ressources en ligne près de son domicile."
          image={Images.offers.partner.feature1}
        />

        <SectionContent
          title="Gestion documentaire et partage d’informations"
          subtitle="La gestion centralisée et le partage d’informations entre toutes les structures"
          paragraph="Publiez, communiquez et partagez des informations utiles, des documents, des capsules vidéos, des moocs ! Ces informations sont visibles pour vous uniquement, pour certaines typologies de membres de la plateforme ou pour tous les visiteurs !"
          image={Images.offers.partner.feature7}
          imageOnLeft
        />

        <SectionContent
          title="Contenu pédagogique en ligne"
          subtitle="Des formations en ligne, Moocs, et Quiz pour les professionnels et les aidants"
          paragraph="Mettez vos propres contenus pédagogiques en ligne. Vos membres peuvent suivre les moocs, participer à des quiz, échanger avec les professionnels et s’évaluer. Suivez l’avancement et mesurez l’impact de vos contenus."
          image={Images.offers.partner.feature8}
        />

        {/* PARTNAIRE SECTION */}
        {/* <Grid container>
          <Grid item className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography variant="h2" align="center" gutterBottom>
                {t('WELLO.HOMEPAGE.PARTNAIRE.TITLE')}
              </Typography>
              <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
                {t('WELLO.HOMEPAGE.PARTNAIRE.SUBTITLE')}
              </Typography>
            </Grid>
          </Grid>
        </Grid> */}
        {/* <Grid className={classes.layoutPartner}>
          <Slider {...settings}>
            <Paper className={classes.root} elevation={0}>
              <LeagueCard league={fakeLeague.league2} />
            </Paper>
            <Paper className={classes.root} elevation={0}>
              <LeagueCard league={fakeLeague.league1} />
            </Paper>
            <Paper className={classes.root} elevation={0}>
              <LeagueCard league={fakeLeague.league8} />
            </Paper>
            <Paper className={classes.root} elevation={0}>
              <LeagueCard league={fakeLeague.league13} />
            </Paper>
            <Paper className={classes.root} elevation={0}>
              <LeagueCard league={fakeLeague.league14} />
            </Paper>
            <Paper className={classes.root} elevation={0}>
              <LeagueCard league={fakeLeague.league15} />
            </Paper>
            <Paper className={classes.root} elevation={0}>
              <LeagueCard league={fakeLeague.league12} />
            </Paper>
            <Paper className={classes.root} elevation={0}>
              <LeagueCard league={fakeLeague.league3} />
            </Paper>
            <Paper className={classes.root} elevation={0}>
              <LeagueCard league={fakeLeague.league4} />
            </Paper>
            <Paper className={classes.root} elevation={0}>
              <LeagueCard league={fakeLeague.league5} />
            </Paper>
            <Paper className={classes.root} elevation={0}>
              <LeagueCard league={fakeLeague.league10} />
            </Paper>
            <Paper className={classes.root} elevation={0}>
              <LeagueCard league={fakeLeague.league9} />
            </Paper>
          </Slider>
        </Grid> */}

        {/* COMMUNITY SECTION */}
        <SectionCommunity
          title="Créer des communautés"
          subTitle="Créez des groupes de discussion pour favoriser l’entraide, le partage, la pair-aidance (par exemple des communautés d’aidants autour d’une pathologie spécifique). Choisissez les membres de chaque groupe parmi tous vos membres (professionnels, aidants, bénévoles, etc.)"
          titleColor1="black"
          titleColor2="black"
          subtitleColor="black"
          backgroundColor="transparent"
          variantTitle="h2"
        />

        <Divider style={{ padding: '2rem', background: 'none' }} />

        <SectionInfo
          title="Planifiez une démo !"
          subTitle="Nous pouvons vous proposer un accès gratuit en moins de 10’"
          buttonTitle="Consulter notre agenda"
          buttonUrl="https://calendly.com/agenda-wello/demo"
          targetBlank
          backgroundColor={muiTheme.wello.primary.dark}
        />

        <Divider style={{ padding: '3rem', background: 'none' }} />
        {/* TECHNOLOGIES SECTION */}
        <div
          style={{
            backgroundImage: `url(${Images.offers.partner.jumbo2})`,
            backgroundSize: 'cover',
          }}
        >
          <Divider style={{ padding: '1rem', background: 'none' }} />
          <SectionMatrice
            title="La technologie au service des personnes fragiles"
            rowItemSM={4}
            features={[
              {
                id: 41,
                image: Images.offers.partner.techfonc1,
                title: 'MOTEUR DE RECHERCHE',
                subTitle:
                  'Un moteur de recherche puissant permettant de trouver les meilleurs réponses pour les utilisateurs et de comprendre leur besoins.',
              },
              {
                id: 42,
                image: Images.offers.partner.techfonc2,
                title: 'GÉO LOCALISATION',
                subTitle:
                  'Tous les professionnels, activités, ressources en ligne sont géolocalisés pour une recherche « près de chez soi ».',
              },
              {
                id: 43,
                image: Images.offers.partner.techfonc3,
                title: 'TOLÉRANCE ORTHOGRAPHIQUE',
                subTitle:
                  'Parce que tout le monde ne sait pas écrire « Alzheimer » ;-) la plateforme propose un module d’intelligence artificielle et de suggestions.',
              },
              {
                id: 44,
                image: Images.offers.partner.techfonc4,
                title: 'ACCÈS AU CARNET DE LIAISON',
                subTitle:
                  'Offrez l’accès au carnet de liaison aux professionnels de santé, aux structures médico-sociales, aux associations de lien social, etc.',
              },
              {
                id: 45,
                image: Images.offers.partner.techfonc5,
                title: 'PERSONALISATION, TRIE FILTRE, FACETTE',
                subTitle:
                  "La plateforme s'adresse aux personnes âgées et aux personnes en situation de handicap ! Affinez vos résultats avec les tris, filtres, facettes ...",
              },
              {
                id: 46,
                image: Images.offers.partner.techfonc6,
                title: 'HÉBÉRGEMENT DE DONNÉES DE SANTÉ',
                subTitle:
                  'Le niveau de sécurité très élevé et son type d’hébergement permet de stocker des données de santé en toute tranquillité.',
              },
            ]}
          />
        </div>

        <SectionContent
          title="Analytique"
          subtitle="Des statistiques et des analyses d’utilisation"
          paragraph="Grâce aux tableaux de pilotage, vous pouvez suivre l’activité sur la plateforme, étudier les profils des visiteurs, améliorer les mots-clés en fonction des recherches réellement effectuées, repérer des besoins et ajuster vos offres..."
          image={Images.offers.partner.feature2}
          imageOnLeft
        />

        <div
          style={{
            backgroundImage: `url(${Images.offers.partner.jumbo2})`,
            backgroundSize: 'cover',
          }}
        >
          <Divider style={{ padding: '1rem', background: 'none' }} />
          <SectionSlider
            title="Votre plateforme de service en marque blanche sur votre territoire"
            items={[
              {
                img: Images.offers.partner.partner1,
                alt: 'Département Nord',
              },
              {
                img: Images.offers.partner.partner2,
                alt: 'UNA',
              },
              {
                img: Images.offers.partner.partner3,
                alt: 'Pays de Mormal',
              },
              {
                img: Images.offers.partner.partner4,
                alt: 'Département Loire Atlantique',
              },
              {
                img: Images.offers.partner.partner5,
                alt: 'Soutenir les Aidants',
              },
            ]}
          />
          <Divider style={{ padding: '2rem', background: 'none' }} />
        </div>
        <SectionContent
          title="Aide aux aidants"
          subtitle="L’organisation de la solidarité pour les aidants"
          paragraph="Les aidants ou personnes isolées de la Métropole de Lille formulent leur  directement en ligne (petit coup de main, aide au numérique, visite, etc.), les bénévoles les reçoivent et peuvent y répondre selon leur préférence et disponibilité. Aidants et bénévoles peuvent également échanger facilement via la plateforme."
          image={Images.offers.partner.feature3}
          imageOnLeft
          buttonLabel="DECOUVRIR"
          buttonLink="https://www.lascalaa.fr/"
          targetBlank
        />

        <SectionContent
          title="Mobilité"
          subtitle="La lutte contre l’isolement des personnes âgées"
          paragraph="Première Communauté de Communes « Amie des Aînés », le Pays de Mormal permet aux personnes fragiles de trouver un transport adapté en fonction de sa demande (taxi, services à la personne, transport solidaire)."
          image={Images.offers.partner.feature4}
          buttonLabel="DECOUVRIR"
          buttonLink="https://www.deplacezvous.fr/"
          targetBlank
        />

        <SectionContent
          title="Services à domicile"
          subtitle="Un accès moderne aux services à la personne"
          paragraph="En quelques clics, les internautes peuvent trouver l’association d’aide à domicile la plus proche de chez soi, faire une demande en ligne et consulter les activités de loisirs et prévention qu’elle propose."
          image={Images.offers.partner.feature5}
          imageOnLeft
          buttonLabel="DECOUVRIR"
          buttonLink="https://www.besoinaideadomicile.fr/"
          targetBlank
        />

        <SectionContent
          title="Relayage, répit"
          subtitle="Des réponses de proximité pour les aidants"
          paragraph="Un panel de réponses dédiées aux aidants : de l’information qualifiée, des modules de formation, des activités de loisirs et des solutions de répit proche de chez soi. Les aidants peuvent faire une demande de relayage directement en ligne, s’inscrire aux activités, rejoindre un groupe de parole…"
          image={Images.offers.partner.feature6}
          buttonLabel="DECOUVRIR"
          buttonLink="https://www.soutenirlesaidants.fr/"
          targetBlank
        />

        <SectionMatrice
          title="Nous pouvons vous accompagner sur tous ces domaines"
          rowItemSM={12}
          buttonLabel="Télécharger la matrice"
          buttonLink={`${Images.offers.partner.matrice}`}
          download
          features={[
            {
              id: 47,
              image: Images.offers.partner.matrice,
            },
          ]}
        />

        {/* FOOTER SECTION */}
        <Divider style={{ padding: '2rem', background: 'none' }} />

        <FooterWello />
        <Footer />
      </Grid>
    </>
  );
};

export default OfferPartner;
