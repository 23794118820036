import React from 'react';

import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import IconButton from '@material-ui/core/IconButton';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import { Images } from '../../Themes';

type Props = {
  classes: Object,
};

const styles = theme => ({
  nobullets: {
    listStyleType: 'none',
  },
  footerLayout: {
    padding: theme.spacing(4),
    backgroundColor: '#0b2442',
  },
  colorText: {
    color: 'white',
  },
  link: {
    textDecorationLine: 'none',
    color: 'white',
  },
  fab: {
    margin: theme.spacing(1),
    color: 'white',
  },
  card: {
    maxWidth: 156,
    backgroundColor: 'transparent',
    marginBottom: 15,
  },
  media: {
    height: 35,
  },
});

function FooterUna(props: Props) {
  const { classes } = props;
  const { t } = useTranslation();

  return (
    <footer className={classes.footerLayout}>
      <Card className={classes.card} elevation={0}>
        <CardMedia className={classes.media} image={Images.wello.logo_fit} />
      </Card>
      <Grid container justifyContent="space-around" spacing={3}>
        <Grid item md={4} sm={4} xs={12}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h3" className={classes.colorText}>
              {t('WELLO.FOOTER.CONTENT_1.TITLE2')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <a href="https://open.wello.fr/" className={classes.link}>
              <Typography variant="caption" className={classes.colorText}>
                {t('WELLO.FOOTER.CONTENT_1.SUBTITLE_6')}
              </Typography>
            </a>
          </Grid>
          <Divider light style={{ margin: '10px', background: 'none' }} />
          <Grid item xs={12}>
            <Typography variant="h6" component="h3" className={classes.colorText}>
              {t('WELLO.FOOTER.CONTENT_1.TITLE')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://info.wello.fr/notre-demarche-de-co-construction/"
              className={classes.link}
            >
              <Typography variant="caption" className={classes.colorText}>
                {t('WELLO.FOOTER.CONTENT_1.SUBTITLE_4')}
              </Typography>
            </a>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://info.wello.fr/le-groupe-de-reflexion-ethique/"
              className={classes.link}
            >
              <Typography variant="caption" className={classes.colorText}>
                {t('WELLO.FOOTER.CONTENT_1.SUBTITLE_3')}
              </Typography>
            </a>
          </Grid>
          <Grid item xs={12}>
            <a href="https://info.wello.fr/le-socle-de-valeurs/" className={classes.link}>
              <Typography variant="caption" className={classes.colorText}>
                {t('WELLO.FOOTER.CONTENT_1.SUBTITLE_2')}
              </Typography>
            </a>
          </Grid>
          <Grid item xs={12}>
            <a href="https://info.wello.fr/notre-histoire/" className={classes.link}>
              <Typography variant="caption" className={classes.colorText}>
                {t('WELLO.FOOTER.CONTENT_1.SUBTITLE_1')}
              </Typography>
            </a>
          </Grid>
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h3" className={classes.colorText}>
              {t('WELLO.FOOTER.CONTENT_2.TITLE')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <a href="https://info.wello.fr/nous-rejoindre/" className={classes.link}>
              <Typography variant="caption" className={classes.colorText}>
                {t('WELLO.FOOTER.CONTENT_1.SUBTITLE_5')}
              </Typography>
            </a>
          </Grid>
          <Grid item xs={12}>
            <a href="mailto:hello@wello.fr" className={classes.link}>
              <Typography variant="caption" className={classes.colorText}>
                {t('WELLO.FOOTER.CONTENT_2.SUBTITLE_1')}
              </Typography>
            </a>
          </Grid>
          <Divider light style={{ margin: '10px', background: 'none' }} />
          <Grid item md={12}>
            <Typography variant="h6" component="h3" className={classes.colorText}>
              {t('UNA.FOOTER.CONTENT_4.TITLE')}
            </Typography>
          </Grid>
          <IconButton
            href="https://www.facebook.com/Wellooo/"
            target="_blank"
            className={classes.fab}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            href="https://twitter.com/wello_margo"
            target="_blank"
            className={classes.fab}
          >
            <TwitterIcon />
          </IconButton>
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h3" className={classes.colorText}>
              {t('WELLO.FOOTER.CONTENT_3.TITLE')}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <a href="https://www.soutenirlesaidants.fr" className={classes.link}>
              <Typography variant="caption" className={classes.colorText}>
                {t('WELLO.FOOTER.CONTENT_3.SUBTITLE_3')}
              </Typography>
            </a>
          </Grid>
          <Grid item xs={12}>
            <a href="https://www.lascalaa.fr" className={classes.link}>
              <Typography variant="caption" className={classes.colorText}>
                {t('WELLO.FOOTER.CONTENT_3.SUBTITLE_1')}
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="https://www.besoinaideadomicile.fr" className={classes.link}>
              <Typography variant="caption" className={classes.colorText}>
                {t('WELLO.FOOTER.CONTENT_3.SUBTITLE_2')}
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="https://www.deplacezvous.fr" className={classes.link}>
              <Typography variant="caption" className={classes.colorText}>
                {t('WELLO.FOOTER.CONTENT_3.SUBTITLE_5')}
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="https://www.mormaletvous.fr" className={classes.link}>
              <Typography variant="caption" className={classes.colorText}>
                {t('WELLO.FOOTER.CONTENT_3.SUBTITLE_4')}
              </Typography>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}

export default withStyles(styles)(FooterUna);
