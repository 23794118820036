import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import { useRouteMatch } from 'react-router';

import { Images, muiTheme } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import SectionHeroOffer from '../../Components/PagesLeagues/SectionHeroOffer';
import SectionCheck from '../../Components/PagesLeagues/SectionCheck';
import SectionMatrice from '../../Components/PagesLeagues/SectionMatrice';
import SectionContentOffer from '../../Components/PagesLeagues/SectionContentOffer';
import SectionSlider from '../../Components/PagesLeagues/SectionSlider';
import SectionInfoCentered from '../../Components/PagesLeagues/SectionInfoCentered';
import FooterWello from '../../PagesLeagues/Wello/Footer';
import Footer from '../../Components/Layout/Footer';
import Seo from '../../Routing/Seo';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: 'white',
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '6rem 2rem 6rem',
  },
  heroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,
    width: '100%',
    height: '84vh',
    backgroundColor: theme.palette.common.greyBackground,
    padding: theme.spacing(9, 0),
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minHeight: '84vh',
    },
  },
  proofLayout: {
    width: '100%',
    background: `linear-gradient(to bottom, ${theme.palette.common.greyBackground}, white)`,
    padding: theme.spacing(7, 0, 9),
  },
  securityLayout: {
    width: '100%',
    backgroundColor: theme.palette.common.greyBackground,
    padding: theme.spacing(7, 0, 5),
  },
}));

const OfferHelpers = () => {
  const classes = useStyles();
  const match = useRouteMatch();

  return (
    <>
      <Helmet>
        {Seo.title('Wello | Aidants : se coordonner autour de son proche à domicile')}
        {Seo.description(
          'L’application numérique pour les aidants qui favorise le partage d’informations autour et avec son proche en perte d’autonomie',
        )}
        {Seo.canonical(6, match.url)}
      </Helmet>
      <Grid className={classes.mainLayout}>
        <Grid className={classes.heroLayout}>
          <SectionHeroOffer
            title1="Une application pour les"
            title2="aidants"
            title2Color={`linear-gradient(to bottom right, ${muiTheme.wello.helpers.light}, ${muiTheme.wello.helpers.main})`}
            body="Vivre sereinement à domicile le plus longtemps possible et rester acteur de son parcours de vie, avec le soutien des professionnels, de son aidant familial et de son entourage."
            subtitle="MAINTIEN À DOMICILE, CARE MANAGEMENT, RETOUR À DOMICILE, ACTIVITÉS DE LIEN SOCIAL, PRÉVENTION, AIDE À DOMICILE, VIE SOCIALE, LUTTE CONTRE L'ISOLEMENT, RÉPIT"
            image={Images.offers.helpers.jumbo_illustration}
          />
        </Grid>

        <Divider style={{ padding: '4rem', background: 'none' }} />

        <SectionCheck
          title="Aidants : soyez rassurés au quotidien"
          checkList={[
            "Restez averti grâce à l'agenda regroupant passage des professionnels et visites informelles du quotidien",
            'Soyez rassuré grâce aux retours réguliers suite aux passages au domicile chez votre proche',
            'Recevez des notifications pour être averti des passages ou informations importantes',
          ]}
          image={Images.offers.helpers.item1}
        />

        <Hidden smUp>
          <Divider style={{ padding: '4rem', background: 'none' }} />
        </Hidden>

        <SectionCheck
          title="Échangez avec les intervenants"
          checkList={[
            "Créez du lien en rassemblant les professionnels et l'entourage sur un même espace d'échange",
            "Sollicitez et coordonnez l'entourage autour du quotidien de votre proche via des suggestions d'activités",
          ]}
          image={Images.offers.helpers.item2}
          imageOnLeft
        />

        <Hidden smUp>
          <Divider style={{ padding: '4rem', background: 'none' }} />
        </Hidden>

        <SectionCheck
          title="Équipez votre proche"
          checkList={[
            "Impliquez votre proche au coeur de son projet de vie via des suggestions d'envies et l'accès à l'espace d'échange",
            "Offrez un accès facile et simplifié pour consulter son agenda et s'inscrire aux activités autour de chez lui",
            "Maintenez le lien avec l'entourage grâce aux photos reçues et aux appels vidéos",
          ]}
          image={Images.offers.helpers.item3}
        />

        <Divider style={{ padding: '5rem', background: 'none' }} />
        <SectionInfoCentered
          title="Planifiez une démo !"
          subtitle="Vous vivez au côté d'un proche aidé, nous pouvons vous proposer un accès gratuit en moins de 10 minutes !"
          buttonTitle="Consulter notre agenda"
          buttonUrl="https://calendly.com/agenda-wello/demo"
          targetBlank
          btnColor={`linear-gradient(to bottom right, ${muiTheme.wello.helpers.light}, ${muiTheme.wello.helpers.main})`}
          backgroundColor={muiTheme.palette.common.greyBackground}
          mailto
        />
        <Divider style={{ padding: '5rem', background: 'none' }} />

        <Grid className={classes.proofLayout}>
          <SectionHeroOffer
            title1="Un outil qui fait"
            title2="ses preuves"
            title2Color={`linear-gradient(to bottom right, ${muiTheme.wello.helpers.light}, ${muiTheme.wello.helpers.main})`}
            body="Notre outil fait ses preuves dans le maintien à domicile en permettant aux bénéficiaires de rester en contact avec leur proche grâce aux messages et échanges de photos, et en soulageant leurs aidants. Il leur permet également d'être acteur de leur quotidien via l'expression de leurs envies."
            image={Images.offers.helpers.device}
            titleH2
          />
        </Grid>

        <SectionContentOffer
          title="Reprise"
          subtitle="Exprimer ses besoins via une tablette adaptée"
          paragraph="<p>Dans le cadre du programme Interreg 2 Mers, nous avons eu l’occasion de travailler sur un projet d’innovation sociale inclusif, dans un mode de recherche action. Le cœur du projet est l’autodétermination des personnes (l’empowerment).</p><p>Avec notre partenaire le Département du Nord, nous avons développé un accès tablette inclusif centré sur les besoins de personnes âgées isolées.  La tablette leur permet de constituer leur réseau interpersonnel (famille, amis, voisins) puis de partager rapidement leurs envies, en vue de les réaliser.</p>"
          image={Images.offers.helpers.rex1}
          imageOnLeft
          buttonLabel="Découvrez Reprise"
          buttonLink="https://www.reprise-envies.fr/"
          targetBlank
          btnBackgroundColor={`linear-gradient(to bottom right, ${muiTheme.wello.helpers.light}, ${muiTheme.wello.helpers.main})`}
        />
        <Divider style={{ padding: '6rem', background: 'none' }} />
        <SectionContentOffer
          title="Une tablette entre nous"
          subtitle="Organiser le quotidien de son proche, même à distance !"
          paragraph="<p>Vous avez dans votre entourage un proche âgé, malade, ou en situation de handicap ? Vous habitez à distance ou ne pouvez pas être disponible à tout moment ?</p><p>Pour autant, votre proche n’est probablement pas seul et il peut être accompagné à son domicile par des professionnels ainsi que par ses amis et ses voisins qui viennent lui rendre visite régulièrement.</p><p>Avec Wello, vous avez la possibilité de tous vous coordonner afin d’échanger au sujet de la vie de votre proche, de son quotidien, ainsi que de son accompagnement santé.</p>"
          image={Images.offers.helpers.rex2}
          btnBackgroundColor={`linear-gradient(to bottom right, ${muiTheme.wello.helpers.light}, ${muiTheme.wello.helpers.main})`}
        />

        <Divider style={{ padding: '5rem', background: 'none' }} />
        <SectionInfoCentered
          title="Planifiez une démo !"
          subtitle="Vous vivez au côté d'un proche aidé, nous pouvons vous proposer un accès gratuit en moins de 10 minutes !"
          buttonTitle="Consulter notre agenda"
          buttonUrl="https://calendly.com/agenda-wello/demo"
          targetBlank
          btnColor={`linear-gradient(to bottom right, ${muiTheme.wello.helpers.light}, ${muiTheme.wello.helpers.main})`}
          backgroundColor={muiTheme.palette.common.greyBackground}
          mailto
        />
        <Divider style={{ padding: '2rem', background: 'none' }} />

        <SectionSlider
          title="Interopérabilité"
          subtitle="La plateforme Wello est conçue pour être interopérable avec les grands référentiels nationaux. Certains d’entre eux sont déjà opérationnels… Contactez-nous !"
          items={[
            {
              img: Images.offers.establishment.ref1,
              alt: 'Identité Nationale de Santé',
            },
            {
              img: Images.offers.establishment.ref2,
              alt: 'Mon Espace Santé',
            },
            {
              img: Images.offers.establishment.ref3,
              alt: 'Soutenir les Aidants',
            },
            {
              img: Images.offers.establishment.ref4,
              alt: 'Dossier Médical Partagé',
            },
            {
              img: Images.offers.establishment.ref5,
              alt: 'Ma Boussole Aidants',
            },
          ]}
        />
        <Divider style={{ padding: '2rem', background: 'none' }} />
        <Grid className={classes.securityLayout}>
          <SectionMatrice
            title="Un très haut niveau de sécurité et de confidentialité"
            rowItemSM={4}
            features={[
              {
                id: 70,
                image: Images.offers.securite1,
                title: 'Interopérabilité Ségur',
                subTitle:
                  'La plateforme Wello est conçue pour être un DUI labellisé Ségur (Dossier Usager Informatisé) et interopérable avec les grands référentiels nationaux (INS, DMP, MS Santé).',
              },
              {
                id: 71,
                image: Images.offers.securite2,
                title: 'Confidentialité et conformité',
                subTitle:
                  'Wello s’engage à fournir une plateforme conforme aux réglementations françaises et européennes.',
              },
              {
                id: 72,
                image: Images.offers.securite3,
                title: 'Sécurité des données de santé',
                subTitle:
                  'Les données sont hébergées en France sur des serveurs certifiés HDS. Wello protège vos données grâce à des contrôles tels que les autorisations et le chiffrement en transit et au repos.',
              },
            ]}
          />
        </Grid>

        {/* FOOTER SECTION */}
        <Divider style={{ padding: '4rem', background: 'none' }} />
        <FooterWello />
        <Footer />
      </Grid>
    </>
  );
};

export default OfferHelpers;
