import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from 'react-slick';
import LayoutStyles, { isMobile } from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  backgroundGrey: {
    padding: theme.spacing(8, 0),
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
  },
  headingContainer: {
    margin: 'auto',
    padding: theme.spacing(6, 0, 10),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(4, 0),
    },
  },
  title: {
    width: '70%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
}));

type Props = {
  title: String,
  subtitle: String,
  items: Array,
  greyBackground: Boolean,
};

const SectionSlider = ({ title, subtitle, items, greyBackground }: Props) => {
  const classes = useStyles();

  const settings = {
    arrows: !isMobile,
    centerMode: false,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 6,
    speed: 500,
    dots: isMobile,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <Grid
      className={classes.backgroundGrey}
      style={greyBackground && { backgroundColor: '#F6F6F6' }}
    >
      <Grid className={classes.layout}>
        <Grid className={classes.headingContainer}>
          <Typography variant="h2" align="center" gutterBottom className={classes.title}>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
              {subtitle}
            </Typography>
          )}
        </Grid>
        <Slider {...settings}>
          {items?.map(item => (
            <img src={item.img} alt={item.alt} key={item.alt} />
          ))}
        </Slider>
      </Grid>
    </Grid>
  );
};

export default SectionSlider;
