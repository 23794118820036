import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LayoutStyles from '../Layout/Styles/LayoutStyles';
import ButtonCallMeBackInModal from '../Shared/ButtonCallMeBackInModal';

const useStyles = makeStyles(theme => ({
  relayageLayout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '5rem 3rem 5rem',
    padding: '3rem 3rem 3rem',
    borderRadius: '4px',
    [theme.breakpoints.only('xs')]: {
      padding: '2rem',
    },
  },
  flexend: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  relayageButtonSection: {
    textAlign: 'right',
  },
  relayageButton: {
    borderColor: '#fff',
    borderWidth: '0.5px',
    marginBottom: '8px',
    '&>*': {
      color: 'white',
    },
  },
  phoneNumber: {
    backgroundColor: '#fff',
    padding: theme.spacing(1, 0),
    borderRadius: 4,
    fontWeight: '600',
  },
}));

type Props = {
  title: String,
  subTitle: String,
  body: String,
  buttonTitle: String,
  buttonUrl: String,
  backgroundColor: String,
  phoneNumber: String,
  targetBlank: Boolean,
  callMeBackInModal: Boolean,
  organisation: Object,
  color: String,
  BtnBackgroundColor: String,
};

function SectionInfo({
  title,
  subTitle,
  buttonTitle,
  buttonUrl,
  backgroundColor,
  body,
  phoneNumber,
  targetBlank,
  callMeBackInModal,
  organisation,
  color,
  BtnBackgroundColor,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      className={classes.relayageLayout}
      style={{
        backgroundColor: `${backgroundColor}`,
      }}
    >
      <Typography
        variant="h3"
        align="left"
        gutterBottom
        style={{ color: color || 'white' }}
        dangerouslySetInnerHTML={{ __html: t(title) }}
      />
      {body && (
        <Typography
          variant="subtitle1"
          component="h3"
          align="left"
          style={{ marginBottom: 16, color: color || 'white' }}
          dangerouslySetInnerHTML={{ __html: t(body) }}
        />
      )}
      <Grid container className={classes.flexend}>
        {subTitle && (
          <Grid item md={9} sm={12}>
            <Typography
              variant="subtitle1"
              component="h3"
              align="left"
              gutterBottom
              style={{ color: color || 'white' }}
              dangerouslySetInnerHTML={{ __html: t(subTitle) }}
            />
          </Grid>
        )}
        {buttonUrl && (
          <Grid item md={3} sm={12} className={classes.relayageButtonSection}>
            <Button
              variant="outlined"
              href={buttonUrl}
              target={targetBlank && '_blank'}
              className={classes.relayageButton}
            >
              {t(buttonTitle)}
            </Button>
          </Grid>
        )}
        {phoneNumber && (
          <Grid item md={3} sm={12} align="center" className={classes.phoneNumber}>
            {phoneNumber}
          </Grid>
        )}
        {callMeBackInModal && (
          <ButtonCallMeBackInModal
            organisation={organisation}
            buttonLabel={buttonTitle}
            withContainedButton
            withPhone
            withEmail
            backgroundColor={BtnBackgroundColor}
            gtmOrigin="landing"
          />
        )}
      </Grid>
    </Grid>
  );
}
export default SectionInfo;
