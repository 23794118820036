import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import LayoutStyles from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
  },
  matriceLayout: {
    ...LayoutStyles.fullWidthLayout,
    justifyContent: 'center',
  },
  featureLayout: {
    justifyContent: 'center',
  },
  itemLayout: {
    padding: '1rem 1rem 1rem 1rem',
    [theme.breakpoints.only('xs')]: {
      padding: '1rem 0',
    },
  },
  matriceTitle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  matriceImage: {
    margin: '15px 0',
    width: '90%',
    height: 'auto',
  },
  title: {
    width: '70%',
    paddingTop: theme.spacing(2),
  },
  avatar: {
    fontsize: 65,
  },
}));

type Props = {
  title: String,
  features: Array,
  rowItemSM: Number,
  textColor: String,
  buttonLabel: String,
  buttonLink: String,
  download: Boolean,
  textAlignLeft: Boolean,
  padding: Boolean,
  outlinedButton: Boolean,
  btnAlign: String,
  featureTitleVariant: String,
  targetBlank: Boolean,
};

function SectionMatrice(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    title,
    rowItemSM,
    features,
    textColor,
    buttonLabel,
    buttonLink,
    download,
    textAlignLeft,
    padding,
    outlinedButton,
    btnAlign,
    featureTitleVariant,
    targetBlank,
  } = props;

  return (
    <Grid align="center" className={classes.layout}>
      {title && (
        <Grid className={classes.title}>
          <Typography
            variant="h2"
            component="h3"
            align="center"
            gutterBottom
            className={classes.matriceTitle}
            style={textColor && { color: textColor }}
          >
            {t(title)}
          </Typography>
        </Grid>
      )}
      <Grid
        container
        className={classes.matriceLayout}
        style={{ padding: padding ? '3rem 3rem 2rem 3rem' : '2rem 0' }}
      >
        {features.map(feature => (
          <Grid
            item
            key={`${feature.id || feature.title}`}
            sm={rowItemSM}
            xs={12}
            className={classes.itemLayout}
            style={{ textAlign: textAlignLeft ? 'left' : 'center' }}
          >
            {feature.image && (
              <img src={feature.image} className={classes.matriceImage} alt={feature.title} />
            )}
            {feature.icon && feature.icon}
            {feature.title && (
              <Typography
                variant={featureTitleVariant || 'h6'}
                component={title ? 'h4' : 'h3'}
                gutterBottom
                className={classes.matriceTitle}
                style={textColor && { color: textColor }}
              >
                {t(feature.title)}
              </Typography>
            )}
            {feature.subTitle && (
              <Typography
                variant="body2"
                gutterBottom
                className={classes.matriceTitle}
                style={textColor && { color: textColor }}
              >
                {t(feature.subTitle)}
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>
      {buttonLabel && (
        <Grid align={btnAlign || 'center'}>
          <Button
            href={buttonLink}
            target={targetBlank ? '_blank' : '_self'}
            download={download}
            variant={outlinedButton && 'outlined'}
          >
            {t(buttonLabel)}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
export default SectionMatrice;
