import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import LayoutStyles from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  heroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  fullWidthLayout: {
    ...LayoutStyles.fullWidthLayout,
  },
  otherLayout: {
    padding: '5rem 2rem',
  },
  heroTitle1: {
    marginBottom: 0,
  },
  heroSubTitle1: {
    paddingBottom: '5rem',
    paddingLeft: '0.3rem',
    textShadow: '0.5px 0.5px #3c3c3c73',
    fontWeight: 400,
  },
  header: {
    fontWeight: '400',
  },
  body: {
    fontWeight: 400,
    paddingTop: theme.spacing(2),
    paddingLeft: '0.3rem',
  },
  image2: {
    marginTop: theme.spacing(5),
  },
  badgeStore: {
    height: 40,
  },
  badgesGrid: {
    marginTop: theme.spacing(5),
  },
}));

type Props = {
  header: String,
  headerColor: String,
  title1: String,
  title1Color1: String,
  title1Color2: String,
  title2: String,
  title2Color1: String,
  title2Color2: String,
  subTitle: String,
  subTitleColor: String,
  body: String,
  bodyColor: String,
  image: String,
  backgroundImage: String,
  children: Node,
  imageMD: Number,
  imageWidth: String,
  imageMarginTop: Number,
  image2: String,
  googlePlayBadge: String,
  appStoreBadge: String,
  alignItems: String,
  bodyH2: Boolean,
  fullWidthLayout: Boolean,
  paddingTop: String,
  layoutHeight: String,
  backgroundColor: String,
  alignImage: String,
  imageHeight: String,
};

function SectionHero({
  header,
  headerColor,
  title1,
  title1Color1,
  title1Color2,
  title2,
  title2Color1,
  title2Color2,
  subTitle,
  subTitleColor,
  body,
  bodyColor,
  image,
  backgroundImage,
  children,
  imageMD,
  imageWidth,
  imageMarginTop,
  image2,
  googlePlayBadge,
  appStoreBadge,
  alignItems,
  bodyH2,
  fullWidthLayout,
  paddingTop,
  layoutHeight,
  backgroundColor,
  alignImage,
  imageHeight,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      className={classes.heroLayout}
      style={{
        backgroundImage: backgroundImage ? backgroundImage : null,
        backgroundColor: backgroundColor || '#000',
        minHeight: layoutHeight ? layoutHeight : '80vh',
      }}
    >
      <Grid
        container
        className={fullWidthLayout && classes.fullWidthLayout}
        style={{ alignItems: alignItems || 'flex-start', paddingTop: paddingTop }}
      >
        <Grid item md={12 - imageMD} xs={12} className={!fullWidthLayout && classes.otherLayout}>
          {header && (
            <Typography
              variant="h3"
              paragraph
              align="left"
              gutterBottom
              className={classes.header}
              style={
                headerColor && {
                  color: `${headerColor}`,
                }
              }
            >
              {header}
            </Typography>
          )}
          <Typography variant="h1" align="left" gutterBottom={!bodyH2} style={{ lineHeight: 1.2 }}>
            <div
              className={classes.heroTitle1}
              style={
                title1Color1 &&
                title1Color2 && {
                  background: `linear-gradient(to right, ${title1Color1} 0%, ${title1Color2} 50%)`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  WebkitBoxDecorationBreak: 'clone',
                }
              }
            >
              {t(title1)}
            </div>
            {title2 && (
              <div
                style={
                  title2Color1 &&
                  title2Color2 && {
                    background: `linear-gradient(to right, ${title2Color1} 0%, ${title2Color2} 50%)`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    WebkitBoxDecorationBreak: 'clone',
                  }
                }
              >
                {t(title2)}
              </div>
            )}
          </Typography>
          {body && (
            <Typography
              variant={bodyH2 ? 'h2' : 'h4'}
              component="h2"
              align="left"
              className={classes.body}
              paragraph
              style={
                bodyColor && {
                  color: `${bodyColor}`,
                }
              }
            >
              {t(body)}
            </Typography>
          )}
          {subTitle && (
            <Typography
              variant="h6"
              paragraph
              align="left"
              style={
                subTitleColor && {
                  color: `${subTitleColor}`,
                }
              }
              className={classes.heroSubTitle1}
            >
              {t(subTitle)}
            </Typography>
          )}
          {image2 && <img src={image2} className={classes.image2} alt={title1} />}
          {googlePlayBadge && appStoreBadge && (
            <Grid item className={classes.badgesGrid}>
              <Button
                style={{ backgroundColor: 'transparent' }}
                href="https://play.google.com/store/apps/details?id=fr.wello.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                target="_blank"
              >
                <img src={googlePlayBadge} alt={title1} className={classes.badgeStore} />
              </Button>
              <Button
                style={{ backgroundColor: 'transparent' }}
                href="https://apps.apple.com/fr/app/wello/id1542746182#?platform=iphone"
                target="_blank"
              >
                <img src={appStoreBadge} alt={title1} className={classes.badgeStore} />
              </Button>
            </Grid>
          )}
          {children}
        </Grid>
        <Hidden only={['xs', 'sm']}>
          <Grid
            item
            align={alignImage || 'center'}
            md={imageMD}
            xs={12}
            style={{ alignSelf: 'center', height: imageHeight || 'auto' }}
          >
            {image && (
              <img
                src={image}
                alt={title1}
                style={{
                  width: imageWidth || '60%',
                  marginTop: imageMarginTop || 0,
                  height: imageHeight || 'auto',
                }}
              />
            )}
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
}
export default SectionHero;
