import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import { useRouteMatch } from 'react-router';

import { Images, muiTheme } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import SectionHeroOffer from '../../Components/PagesLeagues/SectionHeroOffer';
import SectionCheck from '../../Components/PagesLeagues/SectionCheck';
import SectionMatrice from '../../Components/PagesLeagues/SectionMatrice';
import SectionContentOffer from '../../Components/PagesLeagues/SectionContentOffer';
import SectionSlider from '../../Components/PagesLeagues/SectionSlider';
import SectionInfoCentered from '../../Components/PagesLeagues/SectionInfoCentered';
import FooterWello from '../../PagesLeagues/Wello/Footer';
import Footer from '../../Components/Layout/Footer';
import Seo from '../../Routing/Seo';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: 'white',
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '6rem 2rem 6rem',
  },
  heroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,
    width: '100%',
    height: '84vh',
    backgroundColor: theme.palette.common.greyBackground,
    padding: theme.spacing(9, 0),
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minHeight: '84vh',
    },
  },
  proofLayout: {
    width: '100%',
    background: `linear-gradient(to bottom, ${theme.palette.common.greyBackground}, white)`,
    padding: theme.spacing(7, 0, 9),
  },
  securityLayout: {
    width: '100%',
    backgroundColor: theme.palette.common.greyBackground,
    padding: theme.spacing(7, 0, 5),
  },
}));

const OfferResidence = () => {
  const classes = useStyles();
  const match = useRouteMatch();

  return (
    <>
      <Helmet>
        {Seo.title('Wello | Solution numérique pour l’habitat inclusif')}
        {Seo.description(
          'Des outils numériques pour favoriser la vie partagée et la coordination dans un habitat inclusif, pour personnes âgées ou en situation de handicap.',
        )}
        {Seo.canonical(6, match.url)}
      </Helmet>
      <Grid className={classes.mainLayout}>
        <Grid className={classes.heroLayout}>
          <SectionHeroOffer
            title1="Des outils innovants pour l’habitat"
            title2="inclusif"
            title2Color={`linear-gradient(to bottom right, ${muiTheme.wello.residence.light}, ${muiTheme.wello.residence.main})`}
            body="Accompagner les habitants dans leur quotidien et favoriser leur autodétermination, pour partager des moments conviviaux et participer à la vie locale."
            subtitle="HABITAT INCLUSIF POUR PERSONNES ÂGÉES OU PERSONNES EN SITUATION DE HANDICAP, HABITAT PARTAGÉ, ANIMATION VIE SOCIALE ET PARTAGÉE, ACCOMPAGNEMENT À L'AUTONOMIE"
            image={Images.offers.residence.jumbo_illustration}
          />
        </Grid>

        <Divider style={{ padding: '4rem', background: 'none' }} />

        <SectionCheck
          title="Valorisez et partagez la vie de votre habitat"
          checkList={[
            "Partagez auprès des familles les nouvelles de l'habitat en partageant des photos du quotidien",
            "Créez du lien avec l'entourage en l'invitant à venir partager des moments conviviaux",
            "Donnez de la visibilité à votre engagement en mettant en avant vos actions sur le fil d'actualité",
          ]}
          image={Images.offers.residence.item1}
        />

        <Hidden smUp>
          <Divider style={{ padding: '4rem', background: 'none' }} />
        </Hidden>

        <SectionCheck
          title="Accompagnez chaque habitant"
          checkList={[
            'Tenez un suivi personnalisé des habitants grâce aux transmissions internes',
            "Communiquez avec la famille et l'entourage via un espace d'échanges dédié",
            "Échangez avec tous les intervenants sur l'espace personnel de l'habitant",
          ]}
          image={Images.offers.residence.item2}
          imageOnLeft
        />

        <Hidden smUp>
          <Divider style={{ padding: '4rem', background: 'none' }} />
        </Hidden>

        <SectionCheck
          title="Animez la vie de groupe et favorisez la cohésion"
          checkList={[
            'Proposez des activités sur un planning partagé avec tous les habitants',
            "Donnez la possibilité aux habitants de s'inscrire en autonomie aux activités",
            'Gérez vos évènements et suivez les inscriptions grâce à un espace back-office simple',
          ]}
          image={Images.offers.residence.item3}
        />

        <Hidden smUp>
          <Divider style={{ padding: '4rem', background: 'none' }} />
        </Hidden>

        <SectionCheck
          title="Gérez vos habitats avec un outil simple"
          checkList={[
            'Modernisez vos outils de gestion et collaborez avec vos collègues',
            "Centralisez la gestion de vos habitats sur un unique point d'accès",
          ]}
          image={Images.offers.residence.item4}
          imageOnLeft
        />

        <Divider style={{ padding: '5rem', background: 'none' }} />
        <SectionInfoCentered
          title="Planifiez une démo !"
          subtitle="Vous avez un projet d’Habitat inclusif, nous pouvons vous proposer un accès gratuit en moins de 10 minutes !"
          buttonTitle="Consulter notre agenda"
          buttonUrl="https://calendly.com/agenda-wello/demo"
          targetBlank
          btnColor={`linear-gradient(to bottom right, ${muiTheme.wello.residence.light}, ${muiTheme.wello.residence.main})`}
          backgroundColor={muiTheme.palette.common.greyBackground}
          mailto
        />
        <Divider style={{ padding: '5rem', background: 'none' }} />

        <Grid className={classes.proofLayout}>
          <SectionHeroOffer
            title1="Un outil qui fait"
            title2="ses preuves"
            title2Color={`linear-gradient(to bottom right, ${muiTheme.wello.residence.light}, ${muiTheme.wello.residence.main})`}
            body="Notre outil fait ses preuves en habitat inclusif en offrant une interface utilisateur facile à utiliser et accessible, permettant aux personnes ayant des besoins spécifiques, d’utiliser les fonctionnalités en toute autonomie. Il répond aux besoins individuels des utilisateurs en offrant des fonctionnalités de connectivité pour faciliter la communication et la collaboration entre les résidents des habitats."
            image={Images.offers.residence.device}
            titleH2
          />
        </Grid>

        <SectionContentOffer
          title="HAPI’Coop"
          subtitle="Co-construire les outils de gestion des habitats"
          paragraph="<p>La coopérative HAPI’Coop développe des solutions d’habitat pour les personnes en situation de handicap. Ces solutions sont de plus en plus demandées et nécessitent des outils qui permettent de professionnaliser ces initiatives.</p><p>Avec HAPI’Coop, nous avons développé les fonctionnalités qui permettent d’organiser les habitats inclusifs, de la gestion globale des différents habitats (gestion des candidatures, suivi des structures), en passant par l’animation de chaque habitat et jusqu’à la participation des habitants à la vie de groupe. Nous avons donc développé plusieurs accès afin de répondre aux besoins des coordinateurs, des animateurs, ainsi que des habitants.</p>"
          image={Images.offers.residence.rex1}
          imageOnLeft
          buttonLabel="Découvrez Hapicoop"
          buttonLink="https://www.hapicoop.fr/"
          targetBlank
          btnBackgroundColor={`linear-gradient(to bottom right, ${muiTheme.wello.residence.light}, ${muiTheme.wello.residence.main})`}
        />
        <Divider style={{ padding: '6rem', background: 'none' }} />
        <SectionContentOffer
          title="HAPI’Coop"
          subtitle="Un support à l’accompagnement numérique des habitants"
          paragraph="<p>Pour permettre aux habitants, même ceux qui ne possèdent pas de smartphone, d’avoir accès à l’application Wello, une tablette est installée dans l’espace commun de chaque habitat. Cette tablette permet à chaque habitant de se connecter à son espace sécurisé puis de partager du contenu avec son entourage et son habitat. Cet accès permet au professionnel de l’habitat d’accompagner chacun dans son utilisation du numérique et peut servir de support pédagogique pour aborder les questions de sécurité numérique, de confidentialité et de vie privée. L’objectif de la tablette totem est bien de générer de la cohésion, du lien social ainsi que des échanges entre les habitants.</p>"
          image={Images.offers.residence.rex2}
          buttonLabel="Découvrez Hapicoop"
          buttonLink="https://www.hapicoop.fr/"
          targetBlank
          btnBackgroundColor={`linear-gradient(to bottom right, ${muiTheme.wello.residence.light}, ${muiTheme.wello.residence.main})`}
        />

        <Divider style={{ padding: '5rem', background: 'none' }} />
        <SectionInfoCentered
          title="Planifiez une démo !"
          subtitle="Vous avez un projet d’Habitat inclusif, nous pouvons vous proposer un accès gratuit en moins de 10 minutes !"
          buttonTitle="Consulter notre agenda"
          buttonUrl="https://calendly.com/agenda-wello/demo"
          targetBlank
          btnColor={`linear-gradient(to bottom right, ${muiTheme.wello.residence.light}, ${muiTheme.wello.residence.main})`}
          backgroundColor={muiTheme.palette.common.greyBackground}
          mailto
        />
        <Divider style={{ padding: '2rem', background: 'none' }} />

        <SectionSlider
          title="Interopérabilité"
          subtitle="La plateforme Wello est conçue pour être interopérable avec les grands référentiels nationaux. Certains d’entre eux sont déjà opérationnels… Contactez-nous !"
          items={[
            {
              img: Images.offers.establishment.ref1,
              alt: 'Identité Nationale de Santé',
            },
            {
              img: Images.offers.establishment.ref2,
              alt: 'Mon Espace Santé',
            },
            {
              img: Images.offers.establishment.ref3,
              alt: 'Soutenir les Aidants',
            },
            {
              img: Images.offers.establishment.ref4,
              alt: 'Dossier Médical Partagé',
            },
            {
              img: Images.offers.establishment.ref5,
              alt: 'Ma Boussole Aidants',
            },
          ]}
        />
        <Divider style={{ padding: '2rem', background: 'none' }} />
        <Grid className={classes.securityLayout}>
          <SectionMatrice
            title="Un très haut niveau de sécurité et de confidentialité"
            rowItemSM={4}
            features={[
              {
                id: 70,
                image: Images.offers.securite1,
                title: 'Interopérabilité Ségur',
                subTitle:
                  'La plateforme Wello est conçue pour être un DUI labellisé Ségur (Dossier Usager Informatisé) et interopérable avec les grands référentiels nationaux (INS, DMP, MS Santé).',
              },
              {
                id: 71,
                image: Images.offers.securite2,
                title: 'Confidentialité et conformité',
                subTitle:
                  'Wello s’engage à fournir une plateforme conforme aux réglementations françaises et européennes.',
              },
              {
                id: 72,
                image: Images.offers.securite3,
                title: 'Sécurité des données de santé',
                subTitle:
                  'Les données sont hébergées en France sur des serveurs certifiés HDS. Wello protège vos données grâce à des contrôles tels que les autorisations et le chiffrement en transit et au repos.',
              },
            ]}
          />
        </Grid>

        {/* FOOTER SECTION */}
        <Divider style={{ padding: '4rem', background: 'none' }} />
        <FooterWello />
        <Footer />
      </Grid>
    </>
  );
};

export default OfferResidence;
