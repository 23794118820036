import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import { useRouteMatch } from 'react-router';

import { Images, muiTheme } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import SectionHeroOffer from '../../Components/PagesLeagues/SectionHeroOffer';
import SectionCheck from '../../Components/PagesLeagues/SectionCheck';
import SectionMatrice from '../../Components/PagesLeagues/SectionMatrice';
import SectionContentOffer from '../../Components/PagesLeagues/SectionContentOffer';
import SectionSlider from '../../Components/PagesLeagues/SectionSlider';
import SectionInfoCentered from '../../Components/PagesLeagues/SectionInfoCentered';
import FooterWello from '../../PagesLeagues/Wello/Footer';
import Footer from '../../Components/Layout/Footer';
import Seo from '../../Routing/Seo';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: 'white',
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '6rem 2rem 6rem',
  },
  heroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,
    width: '100%',
    height: '84vh',
    backgroundColor: theme.palette.common.greyBackground,
    padding: theme.spacing(9, 0),
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minHeight: '84vh',
    },
  },
  proofLayout: {
    width: '100%',
    background: `linear-gradient(to bottom, ${theme.palette.common.greyBackground}, white)`,
    padding: theme.spacing(7, 0, 9),
  },
  securityLayout: {
    width: '100%',
    backgroundColor: theme.palette.common.greyBackground,
    padding: theme.spacing(7, 0, 5),
  },
}));

const OfferRetirement = () => {
  const classes = useStyles();
  const match = useRouteMatch();

  return (
    <>
      <Helmet>
        {Seo.title('Wello | Outils numériques pour l’EHPAD de demain')}
        {Seo.description(
          'L’application de coordination pour l’EHPAD à domicile, la plateforme de services pour coopérer et partager des ressources et des activités.',
        )}
        {Seo.canonical(6, match.url)}
      </Helmet>
      <Grid className={classes.mainLayout}>
        <Grid className={classes.heroLayout}>
          <SectionHeroOffer
            title1="Des outils innovants pour l’EHPAD"
            title2="de demain"
            title2Color={`linear-gradient(to bottom right, ${muiTheme.wello.retirement.light}, ${muiTheme.wello.retirement.main})`}
            body="Favoriser la coopération des acteurs du médico-social et de l'entourage familial et permettre de vivre à domicile plus longtemps grâce à des dispositifs innovants."
            subtitle="EHPAD RESSOURCES, EHPAD À DOMICILE, DIVADOM, DRAD, SRAD, EHPAD HORS LES MURS, CENTRE DE RESSOURCES TERRITORIAL, CARE MANAGEMENT, MAINTIEN À DOMICILE, LUTTE CONTRE L'ISOLEMENT, VIE SOCIALE, SOINS À DOMICILE"
            image={Images.offers.retirement.jumbo_illustration}
          />
        </Grid>
        <Divider style={{ padding: '4rem', background: 'none' }} />

        <SectionCheck
          title="Des outils de coordination pour l’EHPAD à domicile"
          checkList={[
            'Réalisez un suivi personnalisé des bénéficiaires grâce aux transmissions internes',
            'Coordonnez tous les intervenants à domicile sur un agenda partagé autour de chaque bénéficiaire',
            'Partagez des informations liées à la santé entre professionnels',
            "Créez du lien en rassemblant les professionnels et l'entourage sur un même espace d'échange",
          ]}
          image={Images.offers.retirement.item1}
        />

        <Hidden smUp>
          <Divider style={{ padding: '4rem', background: 'none' }} />
        </Hidden>

        <SectionCheck
          title="Ouvrez votre résidence vers l’exterieur"
          checkList={[
            'Communiquez auprès des familles les nouvelles de la vie de votre établissement en partageant des photos du quotidien',
            "Créez du lien avec l'entourage et le voisinage en invitant à venir partager des moments conviviaux",
            "Donnez de la visibilité à votre engagement en mettant en avant vos actions sur le fil d'actualité",
          ]}
          image={Images.offers.retirement.item2}
          imageOnLeft
        />

        <Hidden smUp>
          <Divider style={{ padding: '4rem', background: 'none' }} />
        </Hidden>

        <SectionCheck
          title="Redonnez de l’autonomie à vos résidents"
          checkList={[
            'Proposez un accès facile aux informations et aux services grâce à une interface simplifiée',
            "Renforcez la participation et la sociabilisation via des suggestions d'activités et l’expression d'envies",
            "Donnez la possibilité aux résidents de s'inscrire en autonomie aux activités",
          ]}
          image={Images.offers.retirement.item3}
        />

        <Divider style={{ padding: '5rem', background: 'none' }} />
        <SectionInfoCentered
          title="Planifiez une démo !"
          subtitle="Vous avez un projet d’EHPAD hors les murs, nous pouvons vous proposer un accès gratuit en moins de 10 minutes !"
          buttonTitle="Consulter notre agenda"
          buttonUrl="https://calendly.com/agenda-wello/demo"
          targetBlank
          btnColor={`linear-gradient(to bottom right, ${muiTheme.wello.retirement.light}, ${muiTheme.wello.retirement.main})`}
          backgroundColor={muiTheme.palette.common.greyBackground}
          mailto
        />
        <Divider style={{ padding: '5rem', background: 'none' }} />

        <Grid className={classes.proofLayout}>
          <SectionHeroOffer
            title1="Un outil qui fait"
            title2="ses preuves"
            title2Color={`linear-gradient(to bottom right, ${muiTheme.wello.retirement.light}, ${muiTheme.wello.retirement.main})`}
            body="Notre outil fait ses preuves en permettant aux résidents de rester connectés avec leur famille et leurs amis en utilisant des fonctionnalités de communication en temps réel, telles que les messageries instantanées. Il aide également les professionnels de santé à organiser leurs équipes et leurs planning."
            image={Images.offers.retirement.device}
            titleH2
          />
        </Grid>

        <SectionContentOffer
          title="DIAPASON 92"
          subtitle="La coordination des professionnels au domicile du bénéficiaire"
          paragraph="<p>La Fondation Aulagnier porte un projet d’EHPAD à domicile à Asnières-sur-Seine, appelé Diapason 92. Dans le cadre de cet accompagnement à domicile, l’application Wello est utilisée pour organiser la coordination des professionnels autour des bénéficiaires de l’EHPAD à leur domicile.</p><p>Afin de permettre à tous les professionnels de se coordonner, une tablette munie de l’application Wello est installée au domicile de chaque bénéficiaire. Les intervenants du domicile ainsi que la famille, les amis et les voisins bénéficient d’un accès sur l’application et peuvent donc organiser la vie à domicile de la personne autour d’un planning partagé et d’espaces d’échanges.</p>"
          image={Images.offers.retirement.rex1}
          imageOnLeft
          buttonLabel="Découvrez Diapason 92"
          buttonLink="https://www.diapason92.fr/"
          targetBlank
          btnBackgroundColor={`linear-gradient(to bottom right, ${muiTheme.wello.retirement.light}, ${muiTheme.wello.retirement.main})`}
        />
        <Divider style={{ padding: '2rem', background: 'none' }} />
        <SectionContentOffer
          title="EHPAD ouverts vers l’extérieur"
          subtitle="La valorisation des activités organisées par les EHPAD"
          paragraph="<p>De plus en plus d’EHPAD font le choix d’ouvrir leurs portes et de s’intégrer davantage à la vie de leur quartier. Les activités de l’EHPAD sont donc ouvertes aux autres habitants du quartier afin de participer à une société plus inclusive.</p><p>Pour organiser cette ouverture vers l’extérieur, l’application Wello permet aux établissements de communiquer sur leurs évènements et leurs activités et de gérer les inscriptions aux différents évènements.</p>"
          image={Images.offers.retirement.rex2}
        />
        <Divider style={{ padding: '2rem', background: 'none' }} />
        <SectionContentOffer
          title="CRT – Centre de Ressources Territorial"
          subtitle="Un appui à la mission des centres de ressources territoriaux"
          paragraph="<p>Les futurs CRT pourront s’appuyer sur l’application Wello afin de soutenir les professionnels du territoire sur différents aspects :
          <ul><li>La coordination interne et/ou la coopération entre les structures partenaires du centre de ressources territorial</li>
          <li>La formation des professionnels et le partage de ressources administratives et logistiques</li>
          <li>La mise en place de l’accompagnement des personnes âgées en perte d’autonomie qui nécessitent un suivi renforcé à domicile, afin de proposer une alternative à l’EHPAD</li>
          <li>La mutualisation de planning d’activités pour les personnes âgées et les aidants</li>
          <li>Le soutien et l'orientation des aidants vers des solutions de répit</li></ul></p>"
          image={Images.offers.retirement.rex3}
          imageOnLeft
        />

        <Divider style={{ padding: '5rem', background: 'none' }} />
        <SectionInfoCentered
          title="Planifiez une démo !"
          subtitle="Vous avez un projet d’EHPAD hors les murs, nous pouvons vous proposer un accès gratuit en moins de 10 minutes !"
          buttonTitle="Consulter notre agenda"
          buttonUrl="https://calendly.com/agenda-wello/demo"
          targetBlank
          btnColor={`linear-gradient(to bottom right, ${muiTheme.wello.retirement.light}, ${muiTheme.wello.retirement.main})`}
          backgroundColor={muiTheme.palette.common.greyBackground}
          mailto
        />
        <Divider style={{ padding: '2rem', background: 'none' }} />

        <SectionSlider
          title="Interopérabilité"
          subtitle="La plateforme Wello est conçue pour être interopérable avec les grands référentiels nationaux. Certains d’entre eux sont déjà opérationnels… Contactez-nous !"
          items={[
            {
              img: Images.offers.establishment.ref1,
              alt: 'Identité Nationale de Santé',
            },
            {
              img: Images.offers.establishment.ref2,
              alt: 'Mon Espace Santé',
            },
            {
              img: Images.offers.establishment.ref3,
              alt: 'Soutenir les Aidants',
            },
            {
              img: Images.offers.establishment.ref4,
              alt: 'Dossier Médical Partagé',
            },
            {
              img: Images.offers.establishment.ref5,
              alt: 'Ma Boussole Aidants',
            },
          ]}
        />
        <Divider style={{ padding: '2rem', background: 'none' }} />
        <Grid className={classes.securityLayout}>
          <SectionMatrice
            title="Un très haut niveau de sécurité et de confidentialité"
            rowItemSM={4}
            features={[
              {
                id: 70,
                image: Images.offers.securite1,
                title: 'Interopérabilité Ségur',
                subTitle:
                  'La plateforme Wello est conçue pour être un DUI labellisé Ségur (Dossier Usager Informatisé) et interopérable avec les grands référentiels nationaux (INS, DMP, MS Santé).',
              },
              {
                id: 71,
                image: Images.offers.securite2,
                title: 'Confidentialité et conformité',
                subTitle:
                  'Wello s’engage à fournir une plateforme conforme aux réglementations françaises et européennes.',
              },
              {
                id: 72,
                image: Images.offers.securite3,
                title: 'Sécurité des données de santé',
                subTitle:
                  'Les données sont hébergées en France sur des serveurs certifiés HDS. Wello protège vos données grâce à des contrôles tels que les autorisations et le chiffrement en transit et au repos.',
              },
            ]}
          />
        </Grid>

        {/* FOOTER SECTION */}
        <Divider style={{ padding: '4rem', background: 'none' }} />
        <FooterWello />
        <Footer />
      </Grid>
    </>
  );
};

export default OfferRetirement;
