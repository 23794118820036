import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Slider from 'react-slick';

import { makeStyles } from '@material-ui/core/styles';
import CommunityCard from '../Block/CommunityCard';
import fakeCommunity from '../../PagesLeagues/Wello/fake-community';
import { isMobile } from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  communityLayout: {
    backgroundSize: 'cover',
    width: '100%',
  },
  headingContainer: {
    width: '100%',
    padding: '3rem 0 3rem',
  },
  communityTitle: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(4),
  },
  communitySubTitle: {
    paddingBottom: theme.spacing(1),
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  communityContainer: {
    marginLeft: '4rem',
    marginRight: '4rem',
    paddingBottom: '2rem',
  },
  rootCommunity: {
    padding: theme.spacing(1, 1),
    backgroundColor: 'transparent',
  },
}));

type Props = {
  title: String,
  subTitle: String,
  titleColor1: String,
  titleColor2: String,
  subtitleColor: String,
  backgroundColor: String,
  variantTitle: String,
};

function SectionCommunity(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    title,
    subTitle,
    backgroundColor,
    titleColor1,
    titleColor2,
    subtitleColor,
    variantTitle,
  } = props;
  const settings = {
    arrows: !isMobile,
    className: 'center',
    centerMode: false,
    infinite: false,
    centerPadding: '90px',
    slidesToShow: 5,
    speed: 500,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Grid
        className={classes.communityLayout}
        style={{
          backgroundColor,
        }}
      >
        <Grid item className={classes.headingContainer}>
          <Grid align="center" item xs={12}>
            <Typography
              variant={variantTitle || 'h3'}
              align="center"
              className={classes.communityTitle}
              style={{
                background: `linear-gradient(to right ,${titleColor1}, ${titleColor2})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                WebkitBoxDecorationBreak: 'clone',
              }}
              gutterBottom
            >
              {t(title)}
            </Typography>
            <Typography
              variant="subtitle1"
              component="h3"
              align="center"
              className={classes.communitySubTitle}
              gutterBottom
              style={{
                color: subtitleColor,
              }}
            >
              {t(subTitle)}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.communityContainer}>
          <Slider {...settings}>
            <Paper className={classes.rootCommunity} elevation={0}>
              <CommunityCard community={fakeCommunity.community1} />
            </Paper>
            <Paper className={classes.rootCommunity} elevation={0}>
              <CommunityCard community={fakeCommunity.community2} />
            </Paper>
            <Paper className={classes.rootCommunity} elevation={0}>
              <CommunityCard community={fakeCommunity.community3} />
            </Paper>
            <Paper className={classes.rootCommunity} elevation={0}>
              <CommunityCard community={fakeCommunity.community4} />
            </Paper>
            <Paper className={classes.rootCommunity} elevation={0}>
              <CommunityCard community={fakeCommunity.community5} />
            </Paper>
          </Slider>
        </Grid>
      </Grid>
    </>
  );
}
export default SectionCommunity;
