import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LayoutStyles from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '4rem 2rem 4rem',
  },
  sectionTitle: {
    paddingBottom: theme.spacing(1),
  },
  subtitle: {
    fontWeight: '600',
    paddingBottom: theme.spacing(1),
  },
  paragraph: {
    paddingTop: theme.spacing(1),
  },
  textContainer: {
    alignSelf: 'center',
  },
  image: {
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    borderRadius: 5,
    boxShadow: theme.shadows['0'],
    height: 'auto',
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));

type Props = {
  title: String,
  h3: Boolean,
  fontWeight: String,
  subtitle: String,
  paragraph: String,
  image: String,
  imageOnLeft: Boolean,
  buttonLabel: String,
  buttonLink: String,
  rowItemTextSM: Number,
  imageUnderText: String,
  textColor: String,
  targetBlank: Boolean,
  variantButton: String,
  btnBackgroundColor: String,
  imgWidth: Number,
};

function SectionContent(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    title,
    h3,
    fontWeight,
    subtitle,
    paragraph,
    imageOnLeft,
    image,
    buttonLabel,
    buttonLink,
    rowItemTextSM,
    imageUnderText,
    textColor,
    targetBlank,
    variantButton,
    btnBackgroundColor,
    imgWidth,
  } = props;

  return (
    <Grid container className={classes.layout}>
      <Grid spacing={3} container style={{ flexDirection: imageOnLeft ? 'row' : 'row-reverse' }}>
        <Grid item md={6} xs={12} align="center">
          <img
            src={image}
            className={classes.image}
            style={{ width: imgWidth || '100%' }}
            alt={title || subtitle}
          />
        </Grid>
        {rowItemTextSM && <Grid item md={6 - rowItemTextSM} />}
        <Grid item md={rowItemTextSM || 6} xs={12} className={classes.textContainer}>
          {title && (
            <Typography
              variant={h3 ? 'h3' : 'h2'}
              component="h3"
              align="left"
              className={classes.sectionTitle}
              style={
                textColor
                  ? { color: textColor, fontWeight: fontWeight || '900' }
                  : { fontWeight: fontWeight || '900' }
              }
              gutterBottom
            >
              {t(title)}
            </Typography>
          )}
          {subtitle && (
            <Typography
              variant="h4"
              component={title ? 'h4' : 'h3'}
              className={classes.subtitle}
              align="left"
              gutterBottom
              style={textColor && { color: textColor }}
            >
              {t(subtitle)}
            </Typography>
          )}
          <Typography
            variant="subtitle1"
            paragraph
            align="left"
            className={classes.paragraph}
            gutterBottom
            dangerouslySetInnerHTML={{ __html: t(paragraph) }}
            style={textColor && { color: textColor }}
          />
          {imageUnderText && (
            <img
              src={imageUnderText}
              className={classes.image}
              style={{ marginTop: 16, width: '100%' }}
              alt={title || subtitle}
            />
          )}
          {buttonLabel && (
            <Grid align={imageOnLeft ? 'right' : 'left'}>
              <Button
                className={classes.button}
                href={buttonLink}
                target={targetBlank ? '_blank' : '_self'}
                variant={variantButton || 'default'}
                style={{ backgroundColor: btnBackgroundColor }}
              >
                {t(buttonLabel)}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default SectionContent;
