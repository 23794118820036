import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LayoutStyles from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
  },
  image: {
    width: '100%',
  },
  textContainer: {
    alignSelf: 'center',
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.only('xs')]: {
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  paragraph: {
    margin: theme.spacing(2, 0),
  },
}));

type Props = {
  title: String,
  subtitle: String,
  paragraph: String,
  image: String,
  imageOnLeft: Boolean,
  buttonLabel: String,
  buttonLink: String,
  imageUnderText: String,
  textColor: String,
  targetBlank: Boolean,
  variantButton: String,
  btnBackgroundColor: String,
};

function SectionContent(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    title,
    subtitle,
    paragraph,
    imageOnLeft,
    image,
    buttonLabel,
    buttonLink,
    targetBlank,
    btnBackgroundColor,
  } = props;

  return (
    <Grid container className={classes.layout}>
      <Grid container style={{ flexDirection: imageOnLeft ? 'row' : 'row-reverse' }}>
        <Grid item md={6} xs={12} align="center">
          <img src={image} className={classes.image} alt={title} />
        </Grid>
        <Grid item md={6} xs={12} className={classes.textContainer}>
          <Typography variant="h3" className={classes.title}>
            {t(title)}
          </Typography>
          <Typography variant="h4">{t(subtitle)}</Typography>
          <Typography
            variant="subtitle1"
            component="div"
            className={classes.paragraph}
            dangerouslySetInnerHTML={{ __html: t(paragraph) }}
          />
          {buttonLabel && (
            <Button
              href={buttonLink}
              target={targetBlank ? '_blank' : '_self'}
              variant="contained"
              style={{ background: btnBackgroundColor, color: 'white' }}
            >
              {t(buttonLabel)}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default SectionContent;
