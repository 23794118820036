import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import { useRouteMatch } from 'react-router';

import { Images, muiTheme } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import SectionHeroOffer from '../../Components/PagesLeagues/SectionHeroOffer';
import SectionCheck from '../../Components/PagesLeagues/SectionCheck';
import SectionMatrice from '../../Components/PagesLeagues/SectionMatrice';
import SectionContentOffer from '../../Components/PagesLeagues/SectionContentOffer';
import SectionSlider from '../../Components/PagesLeagues/SectionSlider';
import SectionInfoCentered from '../../Components/PagesLeagues/SectionInfoCentered';
import FooterWello from '../../PagesLeagues/Wello/Footer';
import Footer from '../../Components/Layout/Footer';
import Seo from '../../Routing/Seo';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: 'white',
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '6rem 2rem 6rem',
  },
  heroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,
    width: '100%',
    height: '84vh',
    backgroundColor: theme.palette.common.greyBackground,
    padding: theme.spacing(9, 0),
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minHeight: '84vh',
    },
  },
  proofLayout: {
    width: '100%',
    background: `linear-gradient(to bottom, ${theme.palette.common.greyBackground}, white)`,
    padding: theme.spacing(7, 0, 9),
  },
  securityLayout: {
    width: '100%',
    backgroundColor: theme.palette.common.greyBackground,
    padding: theme.spacing(7, 0, 5),
  },
}));

const OfferHomeCare = () => {
  const classes = useStyles();
  const match = useRouteMatch();

  return (
    <>
      <Helmet>
        {Seo.title('Wello | Outil numérique pour les services à domicile libérés')}
        {Seo.description(
          'Partager des informations de manière simple avec son équipe, les autres intervenants à domicile et les aidants, autour d’une personne en perte d’autonomie',
        )}
        {Seo.canonical(6, match.url)}
      </Helmet>
      <Grid className={classes.mainLayout}>
        <Grid className={classes.heroLayout}>
          <SectionHeroOffer
            title1="Des outils innovants pour le"
            title2="médico-social"
            title2Color={`linear-gradient(to bottom right, ${muiTheme.wello.homecare.light}, ${muiTheme.wello.homecare.main})`}
            body="S’organiser en équipe, se coordonner avec les autres professionnels et participer au bien-être des personnes âgées ou en situation de handicap."
            subtitle="SERVICES À DOMICILE, SAAD, AIDE À DOMICILE, ORGANISATION LIBÉRÉE, ÉQUIPES AUTONOMES, MODÈLE BUURTZORG"
            image={Images.offers.homecare.jumbo_illustration}
          />
        </Grid>

        <Divider style={{ padding: '4rem', background: 'none' }} />

        <SectionCheck
          title="Coordonnez l’action de vos équipes"
          checkList={[
            'Échangez simplement entre les membres de l’équipe autour de la vie d’équipe et autour des bénéficiaires',
            'Partagez un planning commun des équipes',
            "Partagez des informations avec l'ensemble des intervenants et les aidants sur les aspects du quotidien ou de la santé",
          ]}
          image={Images.offers.homecare.item1}
        />

        <Hidden smUp>
          <Divider style={{ padding: '4rem', background: 'none' }} />
        </Hidden>

        <SectionCheck
          title="Partagez la vie de votre structure"
          checkList={[
            'Une page de présentation administrable, dédiée à votre structure',
            'Mettez en ligne vos activités de loisirs ou de prévention, qui seront ainsi référencées sur les moteurs de recherche',
            'Interagissez et informez votre public',
          ]}
          image={Images.offers.homecare.item2}
          imageOnLeft
        />

        <Hidden smUp>
          <Divider style={{ padding: '4rem', background: 'none' }} />
        </Hidden>

        <SectionCheck
          title="Dossier usager informatisé"
          checkList={[
            'Accès à la fiche numérique complète du bénéficiaire, à la vue 360 de son entourage du bénéficiaire et à son planning',
            'Participez à l’évaluation du bien-être du bénéficiaire',
            'Suivi des prestations et outils de gestion simplifiés',
          ]}
          image={Images.offers.homecare.item3}
        />

        <Hidden smUp>
          <Divider style={{ padding: '4rem', background: 'none' }} />
        </Hidden>

        <SectionCheck
          title="Gérez vos établissements et services avec un outil simple"
          checkList={[
            'Modernisez vos outils de gestion et collaborez avec vos collègues',
            'Recevez des demande de services en ligne et centralisez la gestion de vos services',
            'Des outils simples et modernes pour la gestion administrative',
          ]}
          image={Images.offers.homecare.item4}
          imageOnLeft
        />

        <Divider style={{ padding: '5rem', background: 'none' }} />
        <SectionInfoCentered
          title="Planifiez une démo !"
          subtitle="Vous avez un projet médico-social, nous pouvons vous proposer un accès gratuit en moins de 10 minutes !"
          buttonTitle="Consulter notre agenda"
          buttonUrl="https://calendly.com/agenda-wello/demo"
          targetBlank
          btnColor={`linear-gradient(to bottom right, ${muiTheme.wello.homecare.light}, ${muiTheme.wello.homecare.main})`}
          backgroundColor={muiTheme.palette.common.greyBackground}
          mailto
        />
        <Divider style={{ padding: '5rem', background: 'none' }} />

        <Grid className={classes.proofLayout}>
          <SectionHeroOffer
            title1="Un outil qui fait"
            title2="ses preuves"
            title2Color={`linear-gradient(to bottom right, ${muiTheme.wello.homecare.light}, ${muiTheme.wello.homecare.main})`}
            body="Notre outil fait ses preuves dans le médico-social en permettant une communication et une coordination efficaces entre les différents intervenants du secteur médico-social, tels que les professionnels de santé, les travailleurs sociaux, les aidants et les bénéficiaires. Il offre également des fonctionnalités de gestion de cas pour faciliter la prise en charge des personnes en situation de dépendance ou de vulnérabilité."
            image={Images.offers.homecare.device}
            titleH2
          />
        </Grid>

        <SectionContentOffer
          title="Besoin d’aide à domicile"
          subtitle="La gestion des demandes d’aide à domicile"
          paragraph="<p>Les associations d’aide à domicile du réseau UNA Pays de la Loire ont fait appel à Wello pour créer une plateforme numérique leur permettant de gérer les demandes de service en ligne. Les utilisateurs de besoinaideadomicile.fr peuvent faire leurs demandes d’aide directement depuis la plateforme, puis celles-ci sont gérées par les différents professionnels depuis leur tableau de bord.</p>"
          image={Images.offers.homecare.rex1}
          imageOnLeft
          buttonLabel="Découvrez besoin d’aide à domicile"
          buttonLink="https://www.besoinaideadomicile.fr/"
          targetBlank
          btnBackgroundColor={`linear-gradient(to bottom right, ${muiTheme.wello.homecare.light}, ${muiTheme.wello.homecare.main})`}
        />

        <Divider style={{ padding: '5rem', background: 'none' }} />
        <SectionInfoCentered
          title="Planifiez une démo !"
          subtitle="Vous avez un projet médico-social, nous pouvons vous proposer un accès gratuit en moins de 10 minutes !"
          buttonTitle="Consulter notre agenda"
          buttonUrl="https://calendly.com/agenda-wello/demo"
          targetBlank
          btnColor={`linear-gradient(to bottom right, ${muiTheme.wello.homecare.light}, ${muiTheme.wello.homecare.main})`}
          backgroundColor={muiTheme.palette.common.greyBackground}
          mailto
        />
        <Divider style={{ padding: '2rem', background: 'none' }} />

        <SectionSlider
          title="Interopérabilité"
          subtitle="La plateforme Wello est conçue pour être interopérable avec les grands référentiels nationaux. Certains d’entre eux sont déjà opérationnels… Contactez-nous !"
          items={[
            {
              img: Images.offers.establishment.ref1,
              alt: 'Identité Nationale de Santé',
            },
            {
              img: Images.offers.establishment.ref2,
              alt: 'Mon Espace Santé',
            },
            {
              img: Images.offers.establishment.ref3,
              alt: 'Soutenir les Aidants',
            },
            {
              img: Images.offers.establishment.ref4,
              alt: 'Dossier Médical Partagé',
            },
            {
              img: Images.offers.establishment.ref5,
              alt: 'Ma Boussole Aidants',
            },
          ]}
        />
        <Divider style={{ padding: '2rem', background: 'none' }} />
        <Grid className={classes.securityLayout}>
          <SectionMatrice
            title="Un très haut niveau de sécurité et de confidentialité"
            rowItemSM={4}
            features={[
              {
                id: 70,
                image: Images.offers.securite1,
                title: 'Interopérabilité Ségur',
                subTitle:
                  'La plateforme Wello est conçue pour être un DUI labellisé Ségur (Dossier Usager Informatisé) et interopérable avec les grands référentiels nationaux (INS, DMP, MS Santé).',
              },
              {
                id: 71,
                image: Images.offers.securite2,
                title: 'Confidentialité et conformité',
                subTitle:
                  'Wello s’engage à fournir une plateforme conforme aux réglementations françaises et européennes.',
              },
              {
                id: 72,
                image: Images.offers.securite3,
                title: 'Sécurité des données de santé',
                subTitle:
                  'Les données sont hébergées en France sur des serveurs certifiés HDS. Wello protège vos données grâce à des contrôles tels que les autorisations et le chiffrement en transit et au repos.',
              },
            ]}
          />
        </Grid>

        {/* FOOTER SECTION */}
        <Divider style={{ padding: '4rem', background: 'none' }} />
        <FooterWello />
        <Footer />
      </Grid>
    </>
  );
};

export default OfferHomeCare;
