import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import SectionMatrice from '../../Components/PagesLeagues/SectionMatrice';
import { Images } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import SectionSlider from '../../Components/PagesLeagues/SectionSlider';
import Footer from '../../Components/Layout/Footer';
import FooterWello from '../../PagesLeagues/Wello/Footer';
import { HeartIcon } from '../../Themes/Icon';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: '#ffffff',
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
  },
  btn: {
    margin: theme.spacing(3, 10, 0, 0),
  },
}));

const Partner = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid className={classes.mainLayout}>
      <Divider style={{ padding: '4rem', background: 'none' }} />
      <Grid className={classes.layout}>
        <Typography variant="h2" align="center" gutterBottom>
          Nos utilisateurs <HeartIcon style={{ fontSize: 50, verticalAlign: 'bottom' }} />
        </Typography>
        <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
          Établissements et services sociaux ou médico-sociaux, plateformes de répit, ils ont fait
          le choix d’outils innovants et inclusifs
        </Typography>
        <SectionMatrice
          rowItemSM={4}
          features={[
            {
              id: 25,
              image: Images.partner.user1,
              title: 'Plateforme de répit',
            },
            {
              id: 26,
              image: Images.partner.user2,
              title: 'Association',
            },
            {
              id: 27,
              image: Images.partner.user3,
              title: 'Service à domicile',
            },
            {
              id: 28,
              image: Images.partner.user4,
              title: 'Plateforme de répit',
            },
            {
              id: 29,
              image: Images.partner.user5,
              title: 'Plateforme de répit',
            },
            {
              id: 30,
              image: Images.partner.user6,
              title: 'Habitat inclusif',
            },
            {
              id: 31,
              image: Images.partner.user7,
              title: 'Plateforme de répit',
            },
            {
              id: 32,
              image: Images.partner.user8,
              title: 'EPHAD',
            },
            {
              id: 33,
              image: Images.partner.user9,
              title: 'Service à domicile',
            },
            {
              id: 34,
              image: Images.partner.user10,
              title: 'EPHAD',
            },
            {
              id: 35,
              image: Images.partner.user11,
              title: 'Service à domicile',
            },
            {
              id: 36,
              image: Images.partner.user12,
              title: 'Plateforme de répit',
            },
          ]}
        />
        <Grid align="right">
          <Button onClick={() => history.push('/structures')} className={classes.btn}>
            DECOUVRIR TOUS LES PROFESSIONNELS
          </Button>
        </Grid>
      </Grid>
      <Divider style={{ margin: '4rem', background: 'none' }} />
      <SectionSlider
        title="Nos partenaires financiers"
        subtitle="Acteurs institutionnels, privés ou publics,  ils font le pari de la coopération pour le maintien à domicile et nous accompagnent depuis le début"
        items={[
          {
            img: Images.partner.fin1,
            alt: 'Région Pays de La Loire',
          },
          {
            img: Images.partner.fin2,
            alt: 'France Active',
          },
          {
            img: Images.partner.fin3,
            alt: 'BPI France',
          },
          {
            img: Images.partner.fin4,
            alt: 'MSA',
          },
          {
            img: Images.partner.fin5,
            alt: 'Malakoff humanis',
          },
          {
            img: Images.partner.fin6,
            alt: 'AG2R La Mondiale',
          },
          {
            img: Images.partner.fin7,
            alt: 'Interreg',
          },
          {
            img: Images.partner.fin8,
            alt: 'Département Nord',
          },
          {
            img: Images.partner.fin9,
            alt: 'Réseau Entreprendre',
          },
          {
            img: Images.partner.fin10,
            alt: 'La French Tech',
          },
        ]}
        greyBackground
      />
      <Divider style={{ padding: '1rem', background: 'none' }} />
      <SectionSlider
        title="Nos partenaires de recherche"
        subtitle="Universités, écoles, laboratoire de recherche, cabinets en innovation sociale, ils participent et nous accompagnent dans nos actions de recherche et développement"
        items={[
          {
            img: Images.partner.rd1,
            alt: 'ESO Angers',
          },
          {
            img: Images.partner.rd2,
            alt: 'École de design Nantes Atlantique',
          },
          {
            img: Images.partner.rd3,
            alt: 'Ellyx',
          },
          {
            img: Images.partner.rd4,
            alt: "Université d'Angers",
          },
          {
            img: Images.partner.rd5,
            alt: 'IRTS',
          },
          {
            img: Images.partner.rd6,
            alt: 'ANRT',
          },
        ]}
      />
      <Divider style={{ padding: '1rem', background: 'none' }} />
      <SectionSlider
        title="Nos autres partenaires"
        subtitle="Véritables partenaires opérationnels, ils souhaitent créer des synergies postives pour le mainitien à domicile des personnes en perte d’autonomie"
        items={[
          {
            img: Images.partner.other1,
            alt: 'Soutenir les Aidants',
          },
          {
            img: Images.partner.other2,
            alt: 'Assurance Maladie',
          },
          {
            img: Images.partner.other3,
            alt: 'Banque des Territoires',
          },
          {
            img: Images.partner.other4,
            alt: 'Agirc Arrco',
          },
          {
            img: Images.partner.other5,
            alt: 'Assurance Retraite',
          },
          {
            img: Images.partner.other6,
            alt: 'Assistance publique hopitaux de Paris',
          },
          {
            img: Images.partner.other7,
            alt: 'CHU Nantes',
          },
          {
            img: Images.partner.other8,
            alt: 'Ma Boussole Aidants',
          },
        ]}
        greyBackground
      />
      <Divider style={{ margin: '1rem', background: 'none' }} />

      {/* FOOTER SECTION */}
      <FooterWello />
      <Footer />
    </Grid>
  );
};

export default Partner;
